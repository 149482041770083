exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-internet-js": () => import("./../../../src/pages/internet.js" /* webpackChunkName: "component---src-pages-internet-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-news-alerts-js": () => import("./../../../src/templates/news-alerts.js" /* webpackChunkName: "component---src-templates-news-alerts-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single/Event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/Page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/Post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

